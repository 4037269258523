// getAllProjects

import { createSlice } from "@reduxjs/toolkit";
import routes from '../utils/routes'
import Notification from "../component/notification/Notification";
// import Notification from "../component/notification/Notification";

const initialState = {

    projectOrderWithoutGift: [],
    projectOrderWithGift: [],
    orderUploadedImagesbySubadmin: [],


}


export const OrderSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        getGiftOrderProjects: (state, action) => {
            state.projectOrderWithGift = action.payload
        },
        getWithoutGiftOrderProjects: (state, action) => {
            state.projectOrderWithoutGift = action.payload
        },
        getorderUploadedImagesbySubadminbyId: (state, action) => {
            state.orderUploadedImagesbySubadmin = action.payload
        },
    }
})

export const { getGiftOrderProjects, getWithoutGiftOrderProjects, getorderUploadedImagesbySubadminbyId } = OrderSlice.actions;

export const fetchProjectWithoutGift = (id) => async (dispatch) => {
    try {
        const response = await routes.APIS.getOrdersWithoutGiftByuserId(id);
        // console.log("response??????",response)
        if (response.status === 200) {
            dispatch(getWithoutGiftOrderProjects(response.data))
        }
        return response;
    } catch (error) {
        console.log(error);
        Notification({ message: 'An error occurred during property Fetching.', type: 'error' });
    }
}

export const fetchProjectWithGift = (id) => async (dispatch) => {
    try {
        const response = await routes.APIS.getOrdersWithGiftByuserId(id);
        console.log("responsewithid", response)
        if (response.status === 200) {
            dispatch(getGiftOrderProjects(response.response))
        }
        return response;
    } catch (error) {
        console.log(error);
        Notification({ message: 'An error occurred during property Fetching.', type: 'error' });
    }
}


// Reducer for fetching order uploaded images by Subadmin using orderId
export const fetchOrderUploadedImagesBySubadminById = (orderId) => async (dispatch) => {
    try {
        // Make API call to fetch the data
        const response = await routes.APIS.getorderUploadedImagesbySubadminbyId(orderId);
        console.log("fetchOrderUploadedImagesBySubadminById***", response)
        // Check if the response is successful
        if (response.status === 200) {
            // Dispatch the action with the fetched data
            dispatch(getorderUploadedImagesbySubadminbyId(response.response));
        } else {
            // Handle errors from the API
            // Notification({ message: response.message || 'Failed to fetch order uploaded images.', type: 'error' });
            console.error("Error fetching order uploaded images:", response.message);

        }

        return response;
    } catch (error) {
        console.error("Error fetching order uploaded images:", error);
        // Notification({ message: 'An error occurred while fetching order uploaded images.', type: 'error' });
    }
};



export default OrderSlice.reducer;

