import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjectWithGift, fetchOrderUploadedImagesBySubadminById } from '../../store/orderSlice';
import { fetchEndUserInfo } from '../../store/userSlice';
import { Button, Table, Row, Col } from 'antd';
import { usePDF } from "react-to-pdf";
import GiftCertificate from '../../certificateComp/GiftCertificate';
import PopupGallery from './PopupGallary';

function ProjectOrderWithGiftDataTable() {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userReducer.userInfo);
    const ProjectGiftOrder = useSelector((state) => state.orderReducer.projectOrderWithGift);
    const imagesUploadedbysubadmin = useSelector((state) => state.orderReducer.orderUploadedImagesbySubadmin);

    const { toPDF, targetRef } = usePDF();
    const [certificateData, setCertificateData] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [galleryVisible, setGalleryVisible] = useState(false);
    const [galleryImages, setGalleryImages] = useState([]);
    const [visible, setVisible] = useState(false);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (userInfo?._id) {
            dispatch(fetchProjectWithGift(userInfo?._id));
        }
    }, [dispatch, userInfo?._id]);

    useEffect(() => {
        if (!userInfo._id) {
            dispatch(fetchEndUserInfo(userInfo?._id));
        }
    }, [dispatch, userInfo?._id]);

    const handleDownloadCertificate = (order) => {
        setCertificateData(order);
        setTimeout(() => {
            toPDF({
                filename: `${order?.projectName}-${order?.purchasedDate}-certificate.pdf`,
                page: {
                    format: 'letter',
                    orientation: 'landscape',
                },
            });
        }, 0);
    };

    useEffect(() => {
        if (certificateData) { console.log("certificateData gift****", certificateData) }
    }, [certificateData]);

    const handleTrack = (record) => {
        const { id } = record;
        console.log("Clicked Track, orderId: ", id);
        if (id) {
            dispatch(fetchOrderUploadedImagesBySubadminById(id));
        }
        setVisible(true);
    };
    const dataSource = ProjectGiftOrder.map((order, index) => ({
        key: index,
        id: order?._id,
        projectName: order?.plants[0]?.projectId?.name,
        orderid: order?.payment_info?.orderId,
        location: order?.plants[0]?.projectId?.location,
        giftBy: order?.userId?.name,
        receiverEmail: order?.plants[0]?.giftId?.receiverEmail,
        receiverName: order?.plants[0]?.giftId?.receiverName,
        occasion: order?.plants[0]?.giftId?.occasion || order?.plants[0]?.giftId?.Occasion,
        mobileNumber: order?.plants[0]?.giftId?.mobileNumber,
        quantity: order?.plants[0]?.quantity,
        img: process.env.REACT_APP_BACKEND_URL + "/" + order?.plants[0]?.projectId?.images[0],
        total: order?.total,
        type: order?.plants[0]?.selectType,
        purchasedDate: order.purchasedDate,
        orderstatus: order?.status,
        // images: order?.plants[0]?.projectId?.images ? order.plants[0].projectId.images.map(
        //     (image) => `${process.env.REACT_APP_BACKEND_URL}/${image}`
        // ) : []
    }));

    console.log("giftdataSource********", dataSource);
    console.log("ProjectGiftOrder********", ProjectGiftOrder);

    const columns = [
        {
            title: 'Project Name',
            dataIndex: 'projectName',
            key: 'projectName',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        width={50}
                        src={record?.img}  // Assuming you have the project image URL
                        alt={text}
                        style={{ marginRight: 8 }}
                    />
                    {text}
                </div>
            ),
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: 'Gifted By',
            dataIndex: 'giftBy',
            key: 'giftBy',
        },
        {
            title: 'Receiver Name',
            dataIndex: 'receiverName',
            key: 'receiverName',
        },
        {
            title: 'Receiver Email',
            dataIndex: 'receiverEmail',
            key: 'receiverEmail',
        },
        {
            title: 'Occasion',
            dataIndex: 'occasion',
            key: 'occasion',
        },
        {
            title: 'Mobile No',
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Total Amount',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Purchase Date',
            dataIndex: 'purchasedDate',
            key: 'purchasedDate',
            render: (date) => new Date(date).toLocaleString(),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Button type="default" style={{ marginRight: "10px" }} onClick={() => handleDownloadCertificate(record)}>
                        Download Certificate
                    </Button>
                    <Button
                        key={record.orderId}
                        type="default"
                        onClick={() => handleTrack(record)}

                    >
                        Track
                    </Button>
                </>
            ),
        },
    ];



    const noDataMessage = <div style={{ color: 'white', textAlign: 'center', padding: '20px' }}>No data available</div>;

    return (
        <>
            {isMobile ? (
                dataSource.length === 0 ? noDataMessage : (
                    <Row gutter={16}>
                        {dataSource.map((order) => (
                            <Col span={24} key={order.key}>
                                <div style={{ border: '1px solid #ddd', padding: '10px', marginBottom: '10px', fontWeight: '700' }}>
                                    <p>Project Name:</p>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img
                                            width={50}
                                            src={order.img}
                                            alt={order.projectName}
                                            style={{ marginRight: 8 }}
                                        />
                                        <h3 style={{ margin: 0 }}>{order.projectName}</h3>
                                    </div>
                                    <p>Location: {order.location}</p>
                                    <p>Gifted By: {order.giftBy}</p>
                                    <p>Receiver Name: {order.receiverName}</p>
                                    <p>Receiver Email: {order.receiverEmail}</p>
                                    <p>Occasion: {order.occasion}</p>
                                    <p>Mobile No: {order.mobileNumber}</p>
                                    <p>Quantity: {order.quantity}</p>
                                    <p>Total Amount: {order.total}</p>
                                    <p>Type: {order.type}</p>
                                    <p>Purchased Date: {new Date(order.purchasedDate).toLocaleString()}</p>
                                    <Button type="default" style={{ marginRight: "10px" }} onClick={() => handleDownloadCertificate(order)}>
                                        Download Certificate
                                    </Button>
                                    <Button type="default" onClick={() => handleTrack(order)}>
                                        Track
                                    </Button>
                                </div>
                            </Col>
                        ))}
                    </Row>
                )
            ) : (
                dataSource.length === 0 ? noDataMessage : (
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={{
                            pageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '20', '30', '40'],
                        }}
                        scroll={{ x: 'max-content' }}
                    />
                )
            )}

            {certificateData && (
                <GiftCertificate ref={targetRef} user={userInfo} data={certificateData} />
            )}
            {dataSource.map((item, index) => (
                <PopupGallery
                    key={index} // Always provide a unique key when rendering a list of components
                    visible={visible}
                    onClose={() => setVisible(false)}
                    // images={item.images || []}
                    imagesUploadedbysubadmin={imagesUploadedbysubadmin}
                    status={item.orderstatus}
                />
            ))}
        </>
    );
}

export default ProjectOrderWithGiftDataTable;
