import React, { useEffect, useState } from "react";
import { Modal, Image, Spin, Alert, Divider } from "antd";

const PopupGallery = ({ visible, onClose, imagesUploadedbysubadmin }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (visible) {
            loadImages();
        }
    }, [visible]);

    const loadImages = async () => {
        setLoading(true);
        setError(null);
        try {
            // Simulate image loading process if needed
        } catch (err) {
            setError("Failed to load images. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const renderUploads = () => {
        const uploads = imagesUploadedbysubadmin?.uploads || [];
        if (!uploads.length) {
            return (
                <p
                    style={{
                        color: "black",
                        textAlign: "left",
                        margin: "0",
                        padding: "0",
                    }}
                >
                    No images available.
                </p>
            );
        }

        return uploads.map((upload, index) => {
            const { geoLocation, date, uploadedFiles } = upload;
            const formattedDate = new Date(date).toLocaleDateString("en-GB"); // Format date as dd/mm/yyyy

            return (
                <div
                    key={index}
                    style={{
                        marginBottom: "20px",
                        color: "black",
                        textAlign: "left",
                    }}
                >
                    {/* <Divider
                        orientation="left"
                        style={{
                            color: "black",
                            fontWeight: "bold",
                            textAlign: "left",
                            margin: "0 0 10px 0",
                        }}
                    >
                        <strong>Date:</strong> {formattedDate}
                    </Divider> */}
                    <p
                        style={{
                            color: "black",
                            margin: "0 0 10px 0",
                            padding: "0",
                        }}
                    >
                        <strong>Date:</strong> {formattedDate}

                    </p>
                    {geoLocation && (
                        <p
                            style={{
                                color: "black",
                                margin: "0 0 10px 0",
                                padding: "0",
                            }}
                        >
                            <strong>Location:</strong> {geoLocation.areaName || "Unknown"} ({geoLocation.latitude}, {geoLocation.longitude})
                        </p>
                    )}
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "10px",
                            maxHeight: "300px",
                            overflowY: "auto",
                        }}
                    >
                        {uploadedFiles?.length ? (
                            uploadedFiles.map((file, fileIndex) => {
                                const imageUrl = `${process.env.REACT_APP_BACKEND_URL}/${file.replace(/\\/g, "/")}`;
                                return (
                                    <Image
                                        key={fileIndex}
                                        src={imageUrl}
                                        alt={`Uploaded Image ${fileIndex + 1}`}
                                        width={140}
                                        height={140} // Ensures all images have the same size
                                        preview={true}
                                        style={{
                                            objectFit: "cover", // Ensures images fill the box properly
                                        }}
                                    />
                                );
                            })
                        ) : (
                            <p
                                style={{
                                    color: "black",
                                    margin: "0",
                                    padding: "0",
                                }}
                            >
                                No images uploaded for this entry.
                            </p>
                        )}
                    </div>
                    <Divider
                        style={{
                            marginTop: "20px",
                            borderColor: "black",
                        }}
                    />
                </div>
            );
        });
    };

    return (
        <Modal
            title={
                <span
                    style={{
                        color: "black",
                    }}
                >
                    Track Order
                </span>
            }
            visible={visible}
            onCancel={onClose}
            footer={null}
            width={800} // Adjust width for more content
            style={{
                marginTop: "50px",
            }}
            bodyStyle={{
                maxHeight: "60vh", // Limit height to 60% of viewport height
                overflow: "auto", // Enable x and y scrolling
            }}
        >
            {loading ? (
                <div
                    style={{
                        textAlign: "center",
                        padding: "20px",
                    }}
                >
                    <Spin size="large" />
                </div>
            ) : error ? (
                <Alert
                    message={error}
                    type="error"
                    showIcon
                    style={{
                        color: "black",
                    }}
                />
            ) : (
                renderUploads()
            )}
        </Modal>
    );
};

export default PopupGallery;
